import React, { useRef } from "react"
import { darkTheme } from "src/utils/themes"
import Container from "src/components/Container"
import { LandingPageHeader } from "src/components/Header"
import { ReactTypeformEmbed } from "react-typeform-embed"
import { LightFooter } from "src/components/Footer"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "src/utils/styling"
import { HiChevronRight } from "react-icons/hi"
import { PageButton } from "src/pages/code"
import { PageSection } from "src/components/PageSection"

import Value from "src/components/Value"
import AboutApprenticeship from "src/components/AboutApprenticeship"
import NavSlide from "src/components/NavSlide"
import SEO from "src/components/seo"

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(100)}rem ${rem(140)}rem;
  background: linear-gradient(283.7deg, #03162c 28.07%, #4d5357 185.43%);
  border-radius: 20px;
  width: 90%;
  margin: 0 auto -${rem(180)}rem;
  z-index: 9999;
  @media screen and (max-width: 500px) {
    padding: 60px;
  }
  @media screen and (min-width: 1100px) {
    width: 894px;
  }
`

const CTAContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`

const LandingPageSection = styled(PageSection)`
  padding: ${rem(50)}rem 0;
  position: relative;
  background-color: ${props => (props.color ? props.color : "transparent")};
  @media screen and (min-width: 1100px) {
    padding: ${rem(10)}rem 0;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(5)}rem 0;
  }
  @media screen and (max-width: 550px) {
    padding: ${rem(20)}rem 0;
  }
`

const HeaderQuote = styled.div`
  font-size: ${rem(15)}rem;
  padding: 0px 10px;
  margin: 0 auto;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(15)}rem;
  }
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #595959;
  padding-top: 110px;
`

const QuoteAuthor = styled.div`
  font-size: ${rem(15)}rem;
  margin: 0 auto;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(15)}rem;
  }
  font-style: italic;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: #595959;
  margin: 10px 0px 30px 0px;
`

const IndexPage = ({ location }) => {
  const typeformRef = useRef()
  const onClickTypeform = () => {
    typeformRef.current && typeformRef.current.typeform.open()
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        url="https://jfox813621.typeform.com/to/knCQHiPn"
        ref={typeformRef}
      />
      <SEO
        title="Where talent meets opportunity"
        description="An apprentice program that enables remote talent to audition with curated startups and social enterprises"
        location={location}
        image="seo_custom.png"
      />
      <Container />
      <LandingPageHeader />
      <HeaderQuote>
        “There is nothing more powerful than an idea whose time has come”
      </HeaderQuote>
      <QuoteAuthor>– Victor Hugo</QuoteAuthor>
      <NavSlide />
      <AboutApprenticeship />
      <Value />
      <LandingPageSection>
        <CTAContainer>
          <CTAContainerInner>
            <SectionTitle color={"#fff"} style={{ paddingBottom: 20 }}>
              Join the Apprenticeship Program!
            </SectionTitle>
            <PageButton type="solid" onClick={onClickTypeform}>
              Apply Now
              <HiChevronRight size={22} />
            </PageButton>
          </CTAContainerInner>
        </CTAContainer>
      </LandingPageSection>
      <LightFooter paddingTop={280} />
    </ThemeProvider>
  )
}

export default IndexPage
