import GlobalUser from "src/images/global-user.svg"
import CenterUser from "src/images/center-user.png"
import Mentorship from "src/images/mentors.svg"

const aboutApprenticeshipData = [
  {
    image: GlobalUser,
    title: "Global Program",
    description: "A global paid program for aspiring developers",
  },
  {
    image: CenterUser,
    title: "Elevate talent",
    description: "Uplift talent from non-traditional backgrounds",
  },
  {
    image: Mentorship,
    title: "Mentorship",
    description: "Mentored by the best in the industry",
  },
]

export default aboutApprenticeshipData
