import React from "react"
import { Link } from "gatsby"
import { MobileOnlyCarousel } from "src/components/Carousel"
import Container from "src/components/Container"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import TitleAndBody from "src/components/TitleAndBody"
import valueData from "src/data/valueData"

const ValueContainer = styled.div`
  margin: 1rem 0 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 550px) {
    margin: 1rem 0;
  }
  min-height: ${rem(200)}rem;
  margin-bottom: ${rem(200)}rem;
`

const ValueContainerCardText = styled.div`
  font-size: ${rem(14)}rem;
  color: #000;
  line-height: ${rem(28)}rem;
  font-weight: 300;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(17)}rem;
  }
  @media screen and (max-width: 550px) {
    line-height: ${rem(28)}rem;
  }
  padding: 0px ${rem(30)}rem;
  text-align: center;
  min-height: ${rem(100)}rem;
  margin-top: ${rem(15)}rem;
`

const ValueContainerCardLearnMoreText = styled.div`
  font-size: ${rem(12)}rem;
  color: #000;
  line-height: ${rem(28)}rem;
  font-weight: 900;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(13)}rem;
  }
  @media screen and (max-width: 550px) {
    line-height: ${rem(20)}rem;
  }
  padding: 0px ${rem(30)}rem;
  text-align: center;
  margin-top: 15px;
`

const ValueContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rem(40)}rem ${rem(40)}rem;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
  @media screen and (max-width: 550px) {
    margin: ${rem(20)}rem 0 0;
  }
`

const ValueContainerCardTitle = styled.div`
  color: #fff;
  font-size: ${rem(15)}rem;
  background: ${props => props.bgColor};
  height: fit-content;
  padding: ${rem(9)}rem ${rem(45)}rem;
  width: fit-content;
  text-align: center;
  border-radius: 80px;
  font-weight: 900;

  @media screen and (min-width: 1100px) {
    font-size: ${rem(15)}rem;
    margin: 0 auto;
  }

  @media screen and (max-width: 1100px) {
    margin: 0 auto;
  }
`

const ValueContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin: 0 ${rem(10)}rem;
  padding: ${rem(20)}rem ${rem(20)}rem;
  min-height: ${rem(270)}rem;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.01);
    border: 1px solid #ececec;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${ValueContainerCardTitle} {
      color: #fff;
      transition: all 0.3s ease;
    }
  }
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    flex: 1;
  }
`

const HWText = styled.p`
  color: #000;
  font-weight: 600;
  text-align: center;
  font-size: ${rem(15)}rem;
  line-height: 37px;
  opacity: 0.6;
  margin: 0 auto;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(15)}rem;
    width: 50%;
  }
  text-transform: uppercase;
`

const ValueContainerCardTextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`

const Value = () => {
  return (
    <Container>
      <ValueContainer>
        <HWText>value to stakeholders</HWText>
        <TitleAndBody
          title="What does this mean for you?"
          body="An holistic approach to training and onboarding the next generation of aspiring developers that matches the unique requirements of curated startups"
        />

        <ValueContainerContent>
          <MobileOnlyCarousel>
            {valueData.map(value => (
              <Link to={value.link} style={{ flex: 1 }} key={value.title}>
                <ValueContainerCard>
                  <ValueContainerCardTextContainer>
                    <ValueContainerCardTitle bgColor={value.boxColor}>
                      {value.title}
                    </ValueContainerCardTitle>
                    <ValueContainerCardText>
                      {value.description}
                    </ValueContainerCardText>
                    <ValueContainerCardLearnMoreText>
                      LEARN MORE
                    </ValueContainerCardLearnMoreText>
                  </ValueContainerCardTextContainer>
                </ValueContainerCard>
              </Link>
            ))}
          </MobileOnlyCarousel>
        </ValueContainerContent>
      </ValueContainer>
    </Container>
  )
}

export default Value
