import React from "react"
import { MobileOnlyCarousel } from "src/components/Carousel"
import Container from "src/components/Container"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import TitleAndBody from "src/components/TitleAndBody"
import aboutApprenticeshipData from "src/data/aboutApprenticeshipData"

const ValueContainer = styled.div`
  margin: 5rem 0 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 550px) {
    margin: 1rem 0;
  }
  min-height: ${rem(200)}rem;
  background: #f4fafb;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 12px;
  padding-top: ${rem(70)}rem;
`

const ValueContainerCardText = styled.div`
  font-size: ${rem(14)}rem;
  color: #000;
  line-height: ${rem(28)}rem;
  font-weight: 300;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(17)}rem;
  }
  @media screen and (max-width: 550px) {
    line-height: ${rem(28)}rem;
  }
  padding: 0px ${rem(30)}rem;
  text-align: center;
  width: ${rem(350)}rem;
`

const ValueContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rem(40)}rem ${rem(40)}rem;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
  @media screen and (max-width: 550px) {
    margin: ${rem(20)}rem 0 0;
  }
`

const ValueContainerCardTitle = styled.div`
  font-size: ${rem(10)}rem;
  color: #000;
  height: fit-content;
  padding: ${rem(9)}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 80px;
  font-weight: 900;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(20)}rem;
  }
`

const ValueContainerCard = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 8px;
  padding: ${rem(20)}rem ${rem(0)}rem;
  width: 70%;
  &:hover {
    width: 70%;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff3e1;
    box-shadow: 10px 10px 0px #ffdfaf;
    border-radius: 8px;
    text-align: center;

    ${ValueContainerCardTitle} {
      color: #000;
      transition: all 0.3s ease;
    }
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
    width: 100%;
    margin: 0 ${rem(0)}rem;
    &:hover {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      background: #fff3e1;
      box-shadow: 10px 10px 0px #ffdfaf;
      border-radius: 8px;
      text-align: center;
    }
  }
`

const ValueContainerCardImage = styled.img`
  width: auto;
  height: ${rem(60)}rem;
  margin-bottom: ${rem(10)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(65)}rem;
    height: ${rem(65)}rem;
    margin-bottom: ${rem(20)}rem;
  }
`

const ValueContainerCardTextContainer = styled.div``

const AboutApprenticeship = () => {
  return (
    <Container>
      <ValueContainer>
        <TitleAndBody title="About the Apprenticeship" />
        <ValueContainerContent>
          <MobileOnlyCarousel>
            {aboutApprenticeshipData.map(value => (
              <ValueContainerCard key={value.title}>
                <ValueContainerCardImage src={value.image} />
                <ValueContainerCardTitle>{value.title}</ValueContainerCardTitle>
                <ValueContainerCardText>
                  {value.description}
                </ValueContainerCardText>
              </ValueContainerCard>
            ))}
          </MobileOnlyCarousel>
        </ValueContainerContent>
      </ValueContainer>
    </Container>
  )
}

export default AboutApprenticeship
