const valueData = [
  {
    title: "APPRENTICES",
    description:
      "An opportunity to step into the world of software development",
    boxColor: "linear-gradient(260.25deg, #F093FB -10.66%, #F5576C 113%)",
    link: "/apprenticeship/apprentice",
  },
  {
    title: "STARTUPS",
    description: "Audition talent securely and respectfully before you hire",
    boxColor: "linear-gradient(260.75deg, #B721FF -10.58%, #21D4FD 186.58%)",
    link: "/apprenticeship/startups",
  },
  {
    title: "SOCIAL ENTERPRISES",
    description: "Unlock global talent for your technology needs",
    boxColor: "linear-gradient(260.25deg, #FF6A00 -10.66%, #EE0979 113%)",
    link: "/apprenticeship/social-enterprise",
  },
  {
    title: "MENTORS",
    description:
      "Uplift the next generation of developers from diverse backgrounds",
    boxColor: "linear-gradient(260.25deg, #667EEA -10.66%, #764BA2 113%)",
    link: "/apprenticeship/mentor",
  },
]

export default valueData
