import React from "react"
import styled from "styled-components"
import { Fade } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import "./NavSlide.scss"
import { rem } from "src/utils/styling"

import SliderImage1 from "../images/startups-slider-1.png"
import SliderImage1Mob from "src/images/enterprise-1-m.png"
import SliderImage2 from "../images/apprentice_slide_2.png"
import SliderImage2Mob from "src/images/apprentice_slide_2_mob.png"
import SliderImage3 from "../images/slide-user1.png"

const NavSlide = () => {
  const Container = styled.div`
    position: relative;
    .slide-container {
      margin: 0;

      .react-slideshow-container .default-nav {
        position: absolute;
      }
      .react-slideshow-container .default-nav:first-of-type {
        left: 20px;
      }
      .react-slideshow-container .default-nav:last-of-type {
        right: 20px;
      }
    }

    @media screen and (max-width: 1100px) {
      .slide-container {
        .react-slideshow-container .default-nav {
          position: absolute;
          height: 25px;
          width: 25px;
        }
        .react-slideshow-container .default-nav:first-of-type {
          left: 0px;
        }
        .react-slideshow-container .default-nav:last-of-type {
          right: 0px;
        }
      }
    }
  `

  const Slide = styled.div`
    width: 100%;
    text-align: center;
    height: ${rem(450)}rem;
    background: #03162c;
    display: flex;
    position: relative;
    @media screen and (max-width: 1100px) {
      width: 100%;
      display: inline-block;
      height: ${rem(500)}rem;
    }
  `

  const SlideWithImage = styled.div`
    width: 100%;
    text-align: center;
    height: ${rem(450)}rem;
    background-image: url(${SliderImage1});
    background-size: contain;
    display: flex;
    @media screen and (max-width: 1100px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-image: url(${SliderImage1Mob});
      background-size: cover;
      justify-content: center;
      align-item: center;
      border-radius: 0px;
      height: ${rem(570)}rem;
    }
  `

  const SlideWithImage2 = styled(SlideWithImage)`
    background-image: url(${SliderImage2});
    background-size: cover;
    @media screen and (max-width: 1100px) {
      background-image: url(${SliderImage2Mob});
      background-size: cover;
      background-repeat: no-repeat;
    }
  `

  const TextBox = styled.div`
    width: 60%;
    color: #fff;
    display: flex;
    padding: ${rem(120)}rem ${rem(120)}rem;
    flex-direction: column;
    text-align: left;
    @media screen and (max-width: 1100px) {
      width: 100%;
      text-align: center;
      padding: ${rem(40)}rem ${rem(40)}rem;
    }
  `
  const TextHeader = styled.div`
    font-style: normal;
    font-weight: 900;
    font-size: ${rem(45)}rem;
    line-height: 58px;
    color: #fff;
    max-width: fit-content;
  `
  const SubTextHeader = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: ${rem(20)}rem;
    line-height: 30px;
    color: #ffffff;
    max-width: 320px;
    text-align: left;
    @media screen and (max-width: 1100px) {
      text-align: center;
      max-width: 100%;
      font-size: ${rem(15)}rem;
    }
  `
  const UserBox = styled.div`
    width: 50%;
    color: #fff;
    display: flex;
    padding: ${rem(150)}rem ${rem(120)}rem;
    position: relative;
    @media screen and (max-width: 1100px) {
      width: 100%;
      padding: ${rem(130)}rem 0 0;
      text-align: center;
      display: inline-block;
      margin: ${rem(20)}rem 0 0;
      position: absolute;
      left: 0;
      bottom: -2.4rem;
    }
  `
  const ImageBox = styled.img`
    transform: scale(2.7);
    position: relative;
    height: 140px;
    width: auto;
    @media screen and (max-width: 1100px) {
      position: relative;
      top: ${rem(-90)}rem;
      width: 80px;
      height: 80px;
      right: ${rem(40)}rem;
    }
  `
  const UserTextBox = styled.div`
    width: 100%;
    color: #fff;
    display: flex;
    position: relative;
    left: -75px;
    padding: ${rem(40)}rem ${rem(0)}rem;
    flex-direction: column;
    @media screen and (max-width: 1100px) {
      position: absolute;
      top: 0;
      left: 70px;
      padding: 0;
    }
  `
  const UserNameText = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: ${rem(25)}rem;
    line-height: 30px;
    color: #fff;
    @media screen and (max-width: 1100px) {
      text-align: center;
      font-size: ${rem(20)}rem;
    }
  `

  const FirstNameText = styled.span`
    color: #000;
  `
  const CountryText = styled.span`
    font-weight: 400;
    font-size: ${rem(17)}rem;
    margin-left: ${rem(65)}rem;
  `

  return (
    <Container>
      <div className="slide-container">
        <Fade indicators={true} arrows={true} autoplay={true}>
          <div className="each-fade">
            <SlideWithImage>
              <TextBox>
                <TextHeader>Unlock Global Talent</TextHeader>
                <SubTextHeader>
                  Remote work has provided access to global talent like never
                  before
                </SubTextHeader>
              </TextBox>
            </SlideWithImage>
          </div>
          <div className="each-fade">
            <SlideWithImage2>
              <TextBox>
                <TextHeader>Remote Changes Hiring</TextHeader>
                <SubTextHeader>
                  Traditional recruiting approaches make little sense in a world
                  where talent is remote and often from non-traditional
                  educational and professional backgrounds
                </SubTextHeader>
              </TextBox>
            </SlideWithImage2>
          </div>
          <div className="each-fade">
            <Slide>
              <TextBox>
                <TextHeader>Bridge the Gap</TextHeader>
                <SubTextHeader>
                  A Program that’s designed to upskill aspiring developers to
                  match the unique requirements of startups through auditions,
                  not interviews
                </SubTextHeader>
              </TextBox>
              <UserBox>
                <ImageBox src={SliderImage3} />
                <UserTextBox>
                  <UserNameText>
                    Maithili Deshmukh
                    <br />
                    <CountryText>New York</CountryText>
                  </UserNameText>
                </UserTextBox>
              </UserBox>
            </Slide>
          </div>
        </Fade>
      </div>
      <br />
      <br />
    </Container>
  )
}

export default NavSlide
